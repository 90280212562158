<template>
  <div class="page">
    <div class="page-main">
      <el-form
        class="page-card"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item :label="$t('device.deviceName')">
          <el-input
            v-model="form.deviceName"
            :placeholder="$t('device.deviceNamePla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('device.macAddress')" prop="macAddress">
          <input-mac
            v-model="form.macAddress"
            class="form-item-width"
            :disabled="pageType"
          ></input-mac>
        </el-form-item>
        <el-form-item :label="$t('device.snCode')" prop="snCode">
          <el-input
            v-model="form.snCode"
            :placeholder="$t('device.snCodePla')"
            :disabled="pageType"
            :maxlength="64"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('device.deviceModel')" prop="deviceModel">
          <select-model v-model="form.deviceModel"></select-model>
        </el-form-item>
        <el-form-item :label="$t('device.site')" prop="site">
          <select-site
            v-model="form.site"
            defaultShow
            @change="siteChange"
          ></select-site>
        </el-form-item>
        <el-form-item :label="$t('device.bindAccount')">
          <el-table
            class="tr-stretching-table"
            border
            :data="accountList"
            tooltip-effect="dark"
            style="width: 100%"
          >
          <el-table-column prop="accountNo" :label="$t('device.account')" width="150">
            <template slot-scope="scope">{{ $t('device.account') + scope.row.accountNo }}</template>
          </el-table-column>
          <el-table-column prop="sipUserId" :label="$t('sipAccount.userName')">
            <template slot-scope="scope">{{
              scope.row.sipUserId ? scope.row.sipUserId : '-'
            }}</template>
          </el-table-column>
          <el-table-column prop="serverName" :label="$t('sipServer.serverName')">
            <template slot-scope="scope">{{
              scope.row.serverName ? scope.row.serverName : '-'
            }}</template>
          </el-table-column>
          <el-table-column prop="sipServer" :label="$t('sipServer.sipServer')">
            <template slot-scope="scope">{{
              scope.row.sipServer ? scope.row.sipServer : '-'
            }}</template>
          </el-table-column>
          <el-table-column prop="operation" :label="$t('operation')">
            <template slot-scope="scope">
            <el-button
              type="text"
              class="btn-p0"
              @click="editItem(scope)"
              icon="el-icon-edit iconfont icon-edit"
            ></el-button>
            <el-button
              type="text"
              class="btn-p0"
              @click="deleteItem(scope.row.accountId)"
              icon="el-icon-delete iconfont icon-delete"
              :disabled="!scope.row.sipUserId.length"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
        <!--
        
          <div class="assign-device-item" v-for="(item, i) in accountList" :key="i">
            <div class="assign-device-values">
              <el-select v-model="item.accountNo" :disabled="MACRight">
                <el-option
                  v-for="item1 in accountNoList"
                  :key="item1.id"
                  :label="item1.accountNo"
                  :value="item1.id"
                ></el-option>
              </el-select>
               
            </div>
            
            <span>
                    {{ item.sipUserId }}
                    <span v-if="item.accountName">({{ item.accountName }})</span>
              </span>
              <span class="right">
                    {{ item.serverName }}
                    <span v-if="item.sipServer">({{ item.sipServer }})</span>
              </span>
            <div class="assign-device-edit" @click="editBindAccount(i)">
              <el-tooltip effect="dark" :content="$t('edit')" placement="top" :open-delay="1000">
                <i class="el-icon-search"></i>
              </el-tooltip>
            </div>
          </div>
          <div class="assign-device-add" @click="addBindAccount">
            <el-button
              v-if="form.list.length < accountNoList.length || form.list.length === 0"
              icon="el-icon-plus"
              type="primary"
              :disabled="MACRight || !accountNoList.length"
            >{{ $t('add') }}</el-button>
          </div>-->
        
        <el-form-item class="footer-btns">
          <el-button class="footer-btn" type="primary" plain @click="$router.go(-1)">{{ $t('cancel') }}</el-button>
          <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { InputMac, SelectSite, SelectModel } from '@/components'
import { macFormat } from '@/plugins/methods'
import { deviceQuery, deviceAdd, deviceUpdate, deviceModelAccountNum, verifyMac, deviceAccountList } from '@/api/device'
import { pushDeviceConfig } from '@/api/setParam'
import { unbindDevice } from '@/api/sipAccount'

export default {
  name: 'DeviceDetails',
  components: {
    InputMac,
    SelectSite,
    SelectModel,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      //console.log("validatePass", value)
      if (value === '') {
        this.MACRight = true
        callback(new Error(this.$t('device.macAddressRule')))
      } else if (!macFormat(value)) {
        this.MACRight = true
        callback(new Error(this.$t('device.macAddressRule')))
      } else {
        this.MACRight = false
        callback()
      }
    }
    return {
      oldsite: '',
      pageType: this.$route.query.id ? true : false,
      deviceId: this.$route.query.id,
      MACRight: true,
      accountList: [],
      oldAccountList: [],
      accountNoList: [],
      currentAccountList:[],
      currentAccountNo:1,
      form: {
        deviceName: '',
        macAddress: '',
        snCode: '',
        deviceModel: '',
        site: '',
        list: [],
        accountIdx: '',
      },
      rules: {
        macAddress: {
          required: true,
          validator: validatePass,
          trigger: 'change',
        },
        snCode: {
          required: true,
          message: this.$t('device.snCodeRule'),
          trigger: 'blur',
        },
        site: {
          required: true,
          message: this.$t('device.siteRule'),
          trigger: 'change',
        },
        deviceModel: {
          required: true,
          message: this.$t('device.deviceModelRule'),
          trigger: 'change',
        }
      },
      model:'',
    }
  },
  computed: {
    ...mapState('session', ['isLAN']),
    ...mapGetters('dist', ['accountStatus']),
    
  },
  watch: {
    MACRight(val) {
      !this.pageType && !val && this.checkMacAddress()
    },
    'form.deviceModel'() {
      this.getDeviceModelAccountNum()
    },
    pageType(newValue) {
      console.log("pageType change accountIdx", this.form.accountIdx)
      if (newValue && this.form.accountIdx != 0) {
        let found = false
        if (this.form.accountIdx != 0 && this.form.list.length > 0) {
          this.form.list.forEach((e) => {
            
            if (e.accountNo === this.form.accountIdx) {
                console.log("in watch pageType accountNo", this.form.accountIdx)
                found = true
                this.currentAccountNo = e.accountNo
                this.$router.push(
                  { path: '/device/deviceAccountAssign' , 
                    query: {
                      deviceId: this.$route.query.id,
                      //deviceId: this.deviceId,
                      accountId: e.id,
                      sipUserId: e.sipUserId,
                      sipServer: e.serverName ? e.serverName : e.sipServer,
                      accountNo: e.accountNo,
                      pushStatus: 0,
                      model: this.form.deviceModel,
                    } ,
                  })
                  
            }
          })
        }
        if (!found) {
          this.handleSubmit()

        }
        this.form.accountIdx = 0
      }
    },
  },

  created() {
    console.log("created")
    this.pageType && this.getData(this.$route.query.id)
  },
  
  methods: {
    // 获取数据
    getData(id) {
      
      console.log("getData pageType", this.pageType)
      if (this.pageType) {
        deviceQuery({ id })
          .then(({ data }) => {
            this.form = data.result.rows[0].result.rows[0]
            if (data.result.rows[0].result.rows[0].list == null) {
              this.form.list = []
            }
            this.form.macAddress = this.form.macAddress.toUpperCase()
            this.oldsite = this.form.site

            this.getAccountIdList(id)
            this.getAccountData(id)
          })
          .catch(() => {})
      }
    },
    // 检查mac
    checkMacAddress() {
      verifyMac({ macAddress: this.form.macAddress })
        .then(res => {
          console.log('查询mac', res)
          if (!this.isLAN) this.form.deviceModel = 'M3'
        })
    },
    // 获取账号个数
    getDeviceModelAccountNum() {
      deviceModelAccountNum({ model: this.form.deviceModel }, true)
        .then(({ data }) => {
          let accountNoList = []
          data.result.rows.forEach((e) => {
            accountNoList.push({
              id: e,
              accountNo: this.$t('device.account') + e,
            })
          })
          this.accountNoList = accountNoList
          console.log("getDeviceModelAccountNum", this.form.list.length, accountNoList.length)
          if (this.form.list.length > 0) {
            this.form.list = []
          }
          if (this.form.list.length == 0) {
            this.accountList = []
            accountNoList.forEach((e) => {
              //console.log(e.id)
              this.accountList.push({
                accountNo: e.id,
                sipUserId: '',
                serverName: '',
                sipServer: '',
              })
            })
          }
          this.refresh()
        }
      )
      .catch(() => {})
    },
    addBindAccount() {
      if (!this.MACRight) {
        this.form.list.push({
          accountId: null,
          no: this.accountNoList[0].id,
        })
      }
    },
    deleteBindAccount(i) {
      this.form.list.splice(i, 1)
    },
    siteChange(i) {
      if (i !== 'init') {
        this.form.list.map((e) => (e.accountId = null))
      }
      if (this.form.site && this.pageType && this.deviceId != '') {
        //console.log("siteChange",i)
        //this.getAccountIdList(this.$route.query.id)
        this.getAccountIdList(this.deviceId)
      } else if (!this.form.site) {
        this.accountList = []
      }
    },
    //获取账号列表
    getAccountIdList(id) {
      //console.log("getAccountIdList")
      deviceAccountList( {id} ).then((res) => {
        if (res.data.code == 0) {
          //console.log("getAccountIdList", res.data.result.rows.length)
          this.accountList = res.data.result.rows
          this.oldAccountList = JSON.parse(JSON.stringify(this.accountList))
        }
      })
    },
    deleteItem(accountId) {
      console.log('deleteItem', this.$route.query.id, accountId)
      this.$confirm(this.$t('device.unbindTip'), this.$t('device.unbind'), {
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no'),
        type: 'warning',
        center: true,
      })
      .then(() => {
        let data = {
          accountId,
          //deviceId: this.$route.query.id
          deviceId: this.deviceId
        }
        unbindDevice(data).then(() => {
          //this.pageType && this.getData(this.$route.query.id)
          this.pageType && this.getData(this.deviceId)
          this.refresh()
        })
      })
    },

    // 保存
    submit() {
      if (this.form.list.length !== 0 && this.oldsite !== this.form.site && this.oldsite) {
        this.$message({
          message: this.$t('device.sitechange'),
          type: 'error',
        })
      } else {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.form.list = this.form.list.filter((e) => e.accountId)
            const submitApi = this.pageType ? deviceUpdate : deviceAdd
            submitApi(this.form)
              .then(({ data }) => {
                if (this.$route.query.pushStatus == 0 || this.$route.path === "/device/addDevice") {
                  this.$message.success(data.message)
                  this.$router.go(-1)
                } else {
                  console.log("push in DeviceDetail")
                  this.push()
                }
              })
              .catch(( {data} ) => {
                console.log('submit error ')
                  if (data.messageExt != null) {
                  let idx = data.messageExt.search('Duplicate entry') 
                  if (idx >= 0) {
                     this.$message.error(this.$t('device.duplicateDevice'))
                  }
                }
              })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    push() {
      this.$confirm(this.$t('sitParam2.tipStr'), this.$t('sitParam2.tipTitle'), {
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no'),
        type: 'success',
        center: true,
      })
        .then(() => {
          //pushDeviceConfig(this.$route.query.id)
          pushDeviceConfig(this.deviceId)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.$router.go(-1)
            })
            .catch(() => {})
        })
        .catch(() => {
          this.$router.go(-1)
        })
    },
    getUserItem(accountId) {
      for (let i = 0; i < this.accountList.length; i++) {
        console.log("getUserItem ", accountId, this.accountList[i].id)
        if (this.accountList[i].id == accountId) {
          
          return this.accountList[i]
        }
      }
      return null
    },
    editItem(scope) {
      console.log('editItem', this.$route.query.id, scope.row.id, this.pageType)
      if (this.pageType) {
        this.currentAccountNo = scope.row.accountNo
        this.$router.push(
          { path: '/device/deviceAccountAssign' , 
            query: {
              deviceId: this.$route.query.id,
              //deviceId: this.deviceId,
              accountId: scope.row.id,
              sipUserId: scope.row.sipUserId,
              sipServer: scope.row.serverName ? scope.row.serverName : scope.row.sipServer,
              accountNo: scope.row.accountNo,
              pushStatus: 0,
              deviceModel: this.form.deviceModel
            },
          }
        )
      } else {
        this.$confirm(
            this.$t('device.saveTip'), 
            this.$t('tip'), {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning',
          })
          .then(() => {
            console.log("deviceAdd form", this.form)
            deviceAdd(this.form)
              .then(({ data }) => {
                console.log("addDevice replaced with editDevice path", this.$route.path)
                this.deviceId = data.result.rows[0]
                //this.pageType = true
                //this.$router.removeRoute('addDevice')
                try {
                  this.$router.replace(
                    { path: '/device/editDevice' , 
                      query: {
                      id: data.result.rows[0],
                      model: this.form.deviceModel,
                    }
                  })
                } catch( {data} ) {
                  console.log("push route")
                  this.$router.push(
                    { path: '/device/editDevice' , 
                      query: {
                      id: data.result.rows[0],
                      model: this.form.deviceModel,
                    }
                  })
                }
                this.form.accountIdx = scope.row.accountNo
                this.currentAccountNo = scope.row.accountNo
                this.pageType = true
                /*this.$router.push(
                { path: '/device/deviceAccountAssign' , 
                  query: {
                  //deviceId: this.$route.query.id,
                  deviceId: this.deviceId,
                  accountId: scope.row.id,
                  sipUserId: scope.row.sipUserId,
                  sipServer: scope.row.serverName ? scope.row.serverName : scope.row.sipServer,
                  accountNo: scope.row.accountNo,
                  pushStatus: 0,
                  model: this.form.deviceModel,
                  },
                })*/
              })
              .catch(( {data} ) => {
                console.log('submit error ')
                  if (data.messageExt != null) {
                  let idx = data.messageExt.search('Duplicate entry') 
                  if (idx >= 0) {
                     this.$message.error(this.$t('device.duplicateDevice'))
                  }
                }
              })
          })
      }
    },  
    
    getUserIdList(arr, index) {
      // 需求说明： 账号有两种来源。一种来自edm，对所有话机所有账号可见。一种来自某个话机上报，只对该设备且该账号可见
      let newArr = JSON.parse(JSON.stringify(arr))
      let currentBindAccount = this.oldAccountList[index]
      let bound = currentBindAccount && currentBindAccount.accountId
      // if (index == 0) {
      //   console.log(JSON.stringify(currentBindAccount))
      // }

      // 1. 让当前绑定的账号显示在最上面
      if (bound) {
        newArr.unshift(currentBindAccount)
      }

      // 2. 去除重复account
      if (bound) {
        for (let i = 1; i < newArr.length; i++) {
          if (newArr[i].id === currentBindAccount.id) {
            currentBindAccount.status = newArr[i].status
            currentBindAccount.accountName = newArr[i].accountName
            newArr.splice(i, 1)
            break
          }
        }
      }

      // 3. 删除除了当前绑定的账号之外的origin=2的账号
      let idToDelete = []
      let indexStart
      if (bound) {
        indexStart = 1
      }else{
        indexStart = 0
      }
      for (let i = indexStart; i < newArr.length; i++) {
        if(newArr[i].origin===2) {
          idToDelete.push(newArr[i].id)
        }
      }
      for (let i = 0; i < idToDelete.length; i++) {
        for (let j = 0; j < newArr.length; j++) {
          if (idToDelete[i] === newArr[j].id) {
            newArr.splice(j, 1)
            break
          }
        }
      }
      return newArr
    },
  
    async handleSubmit() {
      this.status = 'Submitting...';

      // 模拟延迟 0.5 秒
      await this.sleep(500);
      console.log("deviceAccountAssign", this.currentAccountNo)
      this.$router.push(
            { path: '/device/deviceAccountAssign' , 
                query: {
                  //deviceId: this.$route.query.id,
                  deviceId: this.deviceId,
                  accountId: '',
                  sipUserId: '',
                  sipServer: '',
                  accountNo: this.currentAccountNo,
                  pushStatus: 0,
                  model: this.form.deviceModel,
                } ,
            })
      // 延迟结束后更新状态
      //this.status = 'Submitted!';
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
 
       }
      
  }
}
</script>

<style lang="scss" scoped>
@include form-page;
.assign-device-item {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  .assign-device-values {
    vertical-align: top;
    display: flex;
    justify-content: space-between;
    width: 100%;
    ::v-deep .el-select {
      // flex: 1;
      position: relative;
      width: calc((100% - 16px) / 2);
      .el-form-item__error {
        position: relative;
        top: 4px;
      }
    }
  }
}
.assign-device-edit {
  position: absolute;
  right: -51px;
  font-size: 30px;
}
.assign-device-add {
  cursor: pointer;
  display: inline-block;
  width: 112px;
  font-size: 24px;
  margin-bottom: 10px;
}
.account-list {
  .icon {
    margin-right: 5px;
  }
  .right {
    float: right;
  }
}
</style>
